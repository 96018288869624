<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="400px"
  >
    <div style="text-align: center; margin: 20px">
      <h2 style="text-align: center">账号登录</h2>
      <div
        style="
          height: 1px;
          width: 100%;
          background-color: #eeeeee;
          margin: 30px 0;
        "
      ></div>
      <div style="display: flex; align-items: center">
        <img
          src="../static/yonghuming.png"
          alt=""
          style="width: 13px; height: 13px; margin: 10px"
        />
        <span>登录名</span>
      </div>
      <el-input
        v-model="formLogin.username"
        placeholder="请输入登录名"
      ></el-input>
      <div style="display: flex; align-items: center">
        <img
          src="../static/mima.png"
          alt=""
          style="width: 13px; height: 13px; margin: 10px"
        />
        <span>密码</span>
      </div>
      <el-input
        v-model="formLogin.password"
        type="password"
        placeholder="请输入密码"
      ></el-input>
      <el-button
        type="primary"
        @click="userLogin"
        style="margin: 40px 0 0 0; width: 100%"
        >立即登录</el-button
      >
      <p>
        没有账号 |
        <span @click="zc" style="cursor: pointer; color: #409eff">注册</span>
      </p>
    </div>
  </el-dialog>
</template>

<script>
import Vrouter from "@/router";
import { login } from "@/api/user.js";
import { setToken } from "@/utils/auth";
export default {
  data() {

    return {
      router: Vrouter,
      dialogFormVisible: true,
      formLogin: {
        username: "",
        password: "",
        clientType: "web",
      },
    };
  },
  methods: {
    zc() {
      this.router.push({
        name: "Register",
      });
      this.close();
    },
    userLogin() {
      if (this.formLogin.username && this.formLogin.password) {
        login(this.formLogin).then((res) => {
          if (res) {
            setToken(res.data.token);
            this.$token = res.data.token;
            location.reload();
            this.close(); // 关闭弹窗
          }
        });
      } else {
        this.$message.error("登录名和密码不能为空");
      }
    },
    open() {
      this.dialogFormVisible = true;
    },
    close() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style>
</style>