import request from '@/utils/request'

//登录
export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data,
  })
}

//注册
export function register(data) {
  return request({
    url: '/user/app/register',
    method: 'post',
    data,
  })
}

//修改密码
export function editPassword(data) {
  return request({
    url: '/user/update-password',
    method: 'put',
    data
  })
}
