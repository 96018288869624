import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Register from '@/views/register'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/homepage',
    children: [
      {
        path: 'homepage',
        component: () => import('@/views/home/HomePage'),
        name: 'HomePage',
        meta: {
          title: '首页',
        }
      },
      {
        path: 'news',
        component: () => import('@/views/home/News.vue'),
        name: 'News',
        meta: {
          title: '资讯信息',
          noCache: true
        }
      },
      {
        path: 'housekeeping',
        component: () => import('@/views/home/Housekeeping'),
        name: 'Housekeeping',
        meta: {
          title: '家政企业',
          noCache: true
        }
      },
      {
        path: 'map',
        component: () => import('@/views/home/map'),
        name: 'Map',
        meta: {
          title: '家政地图',
          noCache: true
        }
      },
      {
        path: 'publicity',
        component: () => import('@/views/home/Publicity'),
        name: 'Publicity',
        meta: {
          title: '信息公示',
          noCache: true
        }
      },
      {
        path: 'certificate',
        component: () => import('@/views/home/Certificate'),
        name: 'Certificate',
        meta: {
          title: '上门服务证',
        }
      },
      {
        path: 'detail',
        component: () => import('@/views/home/NewsDetail'),
        name: 'NewsDetail',
        meta: {
          title: '文章详情',
          noCache: true
        }
      },
      {
        path: 'publicity-detail',
        component: () => import('@/views/home/PublicityDetail'),
        name: 'PublicityDetail',
        meta: {
          title: '公示详情',
          noCache: true
        }
      },
      {
        path: 'company',
        component: () => import('@/views/home/Company'),
        name: 'Company',
        meta: {
          title: '企业详情',
          noCache: true
        }
      },
      {
        path: 'person',
        component: () => import('@/views/home/Person'),
        name: 'Person',
        meta: {
          title: '人员详情',
          noCache: true
        }
      },


    ]
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index.vue'),
    meta: {
      title: '注册',
    }
  },
  {
    path: '/search',
    component: () => import('@/views/home/Search'),
    name: 'Search',
    meta: {
      title: '全站搜索',
    }
  },

]

const router = new VueRouter({
  routes
})

export default router
