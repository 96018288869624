var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f5f5f5","width":"100%","min-height":"100vh"}},[_vm._m(0),_c('div',{staticStyle:{"width":"800px","margin":"20px auto","background":"#fff","border":"1px solid #f5f5f5","box-shadow":"#dddddd","border-radius":"6px","padding":"20px"}},[_c('p',{staticStyle:{"text-align":"center","font-size":"17px","font-weight":"550"}},[_vm._v(" 用户注册 ")]),_c('div',{staticStyle:{"height":"1px","background-color":"#eeeeee","margin":"30px"}}),_c('el-form',{ref:"ruleForm",staticStyle:{"width":"500px","margin":"0 auto"},attrs:{"model":_vm.info,"rules":_vm.rules,"label-width":"90px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":"姓名","prop":"nickname"}},[_c('el-input',{attrs:{"placeholder":"请输入用户姓名"},model:{value:(_vm.info.nickname),callback:function ($$v) {_vm.$set(_vm.info, "nickname", $$v)},expression:"info.nickname"}})],1),_c('el-form-item',{attrs:{"label":"登录名","prop":"username"}},[_c('el-input',{attrs:{"placeholder":"请输入登录名"},model:{value:(_vm.info.username),callback:function ($$v) {_vm.$set(_vm.info, "username", $$v)},expression:"info.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password"}},[_c('el-input',{attrs:{"type":"password","placeholder":"请输入密码"},model:{value:(_vm.info.password),callback:function ($$v) {_vm.$set(_vm.info, "password", $$v)},expression:"info.password"}})],1),_c('el-form-item',{attrs:{"label":"确认密码","prop":"password2"}},[_c('el-input',{attrs:{"type":"password","placeholder":"请输入确认密码"},model:{value:(_vm.info.password2),callback:function ($$v) {_vm.$set(_vm.info, "password2", $$v)},expression:"info.password2"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"sex"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.info.sex),callback:function ($$v) {_vm.$set(_vm.info, "sex", $$v)},expression:"info.sex"}},_vm._l(([
              {
                label: '男',
                value: '0',
              },
              {
                label: '女',
                value: '1',
              },
            ]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"手机号码","prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"请输入手机号码"},model:{value:(_vm.info.phone),callback:function ($$v) {_vm.$set(_vm.info, "phone", $$v)},expression:"info.phone"}})],1),_c('el-form-item',{attrs:{"label":"地址"}},[_c('el-input',{attrs:{"placeholder":"请输入地址"},model:{value:(_vm.info.address),callback:function ($$v) {_vm.$set(_vm.info, "address", $$v)},expression:"info.address"}})],1)],1),_c('div',{staticStyle:{"text-align":"center","margin":"50px 0 20px 0"}},[_c('el-button',{staticStyle:{"width":"120px"},attrs:{"type":"primary"},on:{"click":_vm.zc}},[_vm._v("注册")]),_c('el-button',{staticStyle:{"width":"120px"},on:{"click":_vm.goBack}},[_vm._v("返回")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#fff","padding":"15px 0"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"800px","margin":"0 auto"}},[_c('img',{staticStyle:{"width":"40px","height":"40px","margin-right":"15px"},attrs:{"src":require("../../static/logo.png")}}),_c('div',[_c('p',{staticStyle:{"margin":"0","letter-spacing":"2px","color":"#0059b3","font-weight":"550","font-size":"22px"}},[_vm._v(" 唐山家政综合服务管理平台 ")]),_c('span',{staticStyle:{"font-weight":"550","font-size":"10.5px"}},[_vm._v("Tangshan Domestic Integrated Service Management Platform")])])])])
}]

export { render, staticRenderFns }