<template>
  <div style="background-color: #f5f5f5; width: 100%; min-height: 100vh">
    <div style="background-color: #fff; padding: 15px 0">
      <div
        style="display: flex; align-items: center; width: 800px; margin: 0 auto"
      >
        <img
          style="width: 40px; height: 40px; margin-right: 15px"
          src="../../static/logo.png"
        />
        <div>
          <p
            style="
              margin: 0;
              letter-spacing: 2px;
              color: #0059b3;
              font-weight: 550;
              font-size: 22px;
            "
          >
            唐山家政综合服务管理平台
          </p>
          <span style="font-weight: 550; font-size: 10.5px"
            >Tangshan Domestic Integrated Service Management Platform</span
          >
        </div>
      </div>
    </div>
    <div
      style="
        width: 800px;
        margin: 20px auto;
        background: #fff;
        border: 1px solid #f5f5f5;
        box-shadow: #dddddd;
        border-radius: 6px;
        padding: 20px;
      "
    >
      <p style="text-align: center; font-size: 17px; font-weight: 550">
        用户注册
      </p>
      <div style="height: 1px; background-color: #eeeeee; margin: 30px"></div>
      <el-form
        :model="info"
        :rules="rules"
        ref="ruleForm"
        style="width: 500px; margin: 0 auto"
        label-width="90px"
        label-position="left"
      >
        <el-form-item label="姓名" prop="nickname">
          <el-input
            v-model="info.nickname"
            placeholder="请输入用户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input
            v-model="info.username"
            placeholder="请输入登录名"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="info.password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password2">
          <el-input
            v-model="info.password2"
            type="password"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="info.sex" placeholder="请选择">
            <el-option
              v-for="item in [
                {
                  label: '男',
                  value: '0',
                },
                {
                  label: '女',
                  value: '1',
                },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="info.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="info.address" placeholder="请输入地址"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 50px 0 20px 0">
        <el-button type="primary" @click="zc" style="width: 120px"
          >注册</el-button
        >
        <el-button @click="goBack" style="width: 120px">返回</el-button>
      </div>
    </div>
    <!-- <el-page-header @back="goBack" content="注册"> </el-page-header> -->
  </div>
</template>

<script>
import { register } from "@/api/user.js";
import { Message } from "element-ui";
export default {
  data() {
    const validateMobilePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不可为空"));
      } else {
        if (value !== "") {
          let reg = /^1[3456789]\d{9}$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的手机号码"));
          }
        }
        callback();
      }
    };
    const validPassword = (rule, value, callback) => {
      let reg =
        /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/;
      if (!reg.test(value)) {
        callback(new Error("密码必须是8位以上大小写字母+数字组合"));
      } else {
        callback();
      }
    };
    return {
      info: {
        username: "",
        password: "",
        password2: "",
        nickname: "",
        phone: "",
        sex: "0", //用户性别（0男，1女，2未知）
        userType: "3", //用户类型（2：自由职业者，3：消费者）
        address: "",
      },
      rules: {
        username: [
          { required: true, message: "登录名不能为空", trigger: "blur" },
        ],
        nickname: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, validator: validPassword, trigger: "change" },
        ],
        password2: [
          { required: true, validator: validPassword, trigger: "change" },
        ],
        phone: [
          { required: true, validator: validateMobilePhone, trigger: "blur" },
        ],
        sex: [{ required: true, message: "性别不能为空", trigger: "change" }],
      },
    };
  },
  methods: {
    zc() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.info.password != this.info.password2) {
            this.$message.error("两次密码输入不一致");
            return;
          } else {
            register(this.info).then((res) => {
              Message({
                message: res.message,
                type: "success",
                duration: 2 * 1000,
              });
              this.$router.back();
            });
          }
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style>
</style>