<template>
  <div class="home">
    <div style="background: linear-gradient(to bottom, #fcfcfc, #eaeaea)">
      <div class="home-login">
        <div style="display: flex">
          <!-- <span
            style="
              color: #0059b3;
              font-size: 13px;
              font-weight: 600;
              cursor: pointer;
            "
            ><i class="el-icon-platform-eleme" style="margin: 0 5px"></i
            >手机APP<i class="el-icon-arrow-down"></i
          ></span> -->
          <el-popover placement="bottom" width="150" trigger="click">
            <img
              style="width: 140px; height: 140px; margin-left: 5px"
              src="../static/xiaochengxu.jpg"
            />
            <span slot="reference" class="wx-title"
              >微信小程序<i class="el-icon-arrow-down"></i
            ></span>
          </el-popover>
        </div>
        <div style="display: flex; font-size: 13px">
          <template v-if="$token">
            <span style="cursor: pointer" @click="tcdl"
              >退出登录</span
            ></template
          >
          <template v-else>
            <span style="margin-right: 10px; cursor: pointer" @click="zc"
              >消费者注册</span
            >
            <span style="margin-right: 10px; cursor: pointer" @click="qyrz"
              >企业入驻</span
            >
            <span style="cursor: pointer" @click="dl">登录</span>
          </template>
        </div>
      </div>
    </div>

    <div class="home-name">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 1200px;
          margin: 0 auto;
          padding: 40px 0;
        "
      >
        <div style="display: flex; align-items: center">
          <img
            style="width: 60px; height: 60px; margin-right: 15px"
            src="../static/logo.png"
          />
          <div>
            <p
              style="
                margin: 0 0 3px 0;
                letter-spacing: 2px;
                color: #0059b3;
                font-weight: 550;
                font-size: 32px;
              "
            >
              唐山家政综合服务管理平台
            </p>
            <span style="font-weight: 550"
              >Tangshan Domestic Integrated Service Management Platform</span
            >
          </div>
        </div>
        <div>
          <div
            style="
              display: flex;
              border: 1px solid #d7d7d7;
              border-radius: 20px;
              padding: 1px 1px 1px 15px;
              align-items: center;
            "
          >
            <el-input
              v-model="searchText"
              placeholder="输入关键词进行全站查找"
            ></el-input>
            <span
              style="
                padding: 7px 15px;
                width: 100px;
                font-size: 15px;
                font-weight: 600;
                color: #006cd8;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                cursor: pointer;
                background: linear-gradient(to bottom, #cacaca, #f7f7f7);
              "
              @click="search"
              >开始查找</span
            >
          </div>
          <p style="margin: 8px 15px">
            热门搜索：
            <span
              style="margin-right: 10px; cursor: pointer"
              v-for="item in searchList"
              :key="item"
              @click="searchClick(item)"
              >{{ item }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div style="background: #3f3f3f">
      <div class="home-tabs">
        <zq-tabs :tabs="tabs"></zq-tabs>
      </div>
    </div>

    <div style="background-color: #fff; padding: 15px 0">
      <div>
        <keep-alive>
          <router-view v-if="!$route.meta.noCache" :key="key" />
        </keep-alive>
        <!-- <keep-alive :include="cachedViews"> -->
        <router-view v-if="$route.meta.noCache" :key="key" />
      </div>
    </div>

    <home-bottom></home-bottom>
  </div>
</template>

<script>
// @ is an alias to /src
import ZqTabs from "@/components/ZqTabs.vue";
import { getArea } from "@/api/area.js";
import { getToken, removeToken } from "@/utils/auth";
import HomeBottom from "@/components/HomeBottom.vue";
export default {
  name: "HomeView",
  components: {
    ZqTabs,
    HomeBottom,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  data() {
    return {
      searchText: "",
      searchList: ["月嫂", "保姆", "保洁"],
      selectTab: "",
      tabs: [
        {
          label: "首页",
          name: "HomePage",
          img: "sy",
        },
        {
          label: "资讯信息",
          name: "News",
          img: "zxxx",
        },
        {
          label: "家政企业",
          name: "Housekeeping",
          img: "zjz",
        },
        {
          label: "信息公示",
          name: "Publicity",
          img: "xxgs",
        },
        {
          label: "上门服务证",
          name: "Certificate",
          img: "smfwz",
        },
      ],
    };
  },
  methods: {
    searchClick(text) {
      this.$root.keyword = text;
      this.$router.push({
        name: "Search",
      });
    },
    search() {
      this.$root.keyword = this.searchText;
      this.$router.push({
        name: "Search",
        params: { keyword: this.searchText },
      });
    },
    dl() {
      this.$login();
    },
    zc() {
      this.$router.push({
        name: "Register",
      });
    },
    qyrz() {
      window.open(this.$htPath);
    },
    tcdl() {
      removeToken();
      this.$token = "";
      location.reload();
    },
  },
};
</script>
<style lang="scss">
.home-login {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin: 0 auto;
  width: 1200px;
}
.home-name {
  background-image: url("../static/title_bg.png");
  width: 100%;
  .el-input__inner {
    border: none;
    padding: 0;
    height: 26px;
    line-height: 26px;
    background: none;
  }
}
.home-tabs {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1200px;
  background: #0059b2;
}
.wx-title {
  cursor: pointer;
  margin-left: 15px;
  font-size: 13px;
}
.wx-title:hover {
  color: #0059b3;
}
</style>
