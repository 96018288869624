import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import login from "./common/LoginDialog"; // 登录弹窗全局组件
import 'default-passive-events'
import { getToken } from './utils/auth';
import BaiduMap from 'vue-baidu-map';
// import 'default-passive-events'
// import '@/utils/browser_patch'
Vue.use(BaiduMap, {
  ak: 'V5Km7k4KvkeZwcON9KaPRXucEPndS2xt'
});
Vue.prototype.$login = login.install; // 全局可使用this.$login调用
Vue.prototype.$token = getToken(); //token
Vue.prototype.$htPath = "http://manage.tsjzfw.com"; //后台管理平台地址
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  data: function () {
    return {
      keyword: '',
    }
  },
  el: '#app',
  render: h => h(App)
}).$mount('#app')
