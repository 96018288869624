const TokenKey = 'Admin-Token'
const UserInfo = 'UserInfo'

export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}

export function getUserInfo() {
  return sessionStorage.getItem(UserInfo)
}

export function setUserInfo(userinfo) {
  return sessionStorage.setItem(UserInfo, userinfo)
}

export function removeUserInfo() {
  return sessionStorage.removeItem(UserInfo)
}

