<template>
  <div
    style="
      display: flex;
      justify-content: space-around;
      width: 1200px;
      background-color: #3f3f3f;
    "
  >
    <div
      v-for="(item, index) in tabs"
      :key="index"
      style="display: flex; flex: 1; align-items: center"
      :class="$route.name === item.name ? 'orange' : 'tab-title'"
    >
      <p
        style="
          flex: 1;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          margin: 10px;
          letter-spacing: 2px;
        "
        @click="itemClick(index)"
      >
        {{ item.label }}
      </p>
      <!-- <div
        v-if="index != tabs.length - 1"
        style="height: 25px; width: 1px; background: #fff"
      ></div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemClick(index) {
      this.tabs.forEach((item) => {
        item.selected = false;
      });
      this.tabs[index].selected = true;
      this.$router.push({ name: this.tabs[index].name });
    },
  },
};
</script>

<style lang="scss">
.orange {
  color: #fff;
  background-color: #0059b3;
  cursor: pointer;
}
.tab-title {
  color: #fff;
  cursor: pointer;
}
.tab-title:hover {
  color: #f7b92e;
}
</style>