import request from '@/utils/request'

//地区
export function getArea() {
    return request({
        url: '/area/list-tree',
        method: 'get',
    })
}
//获取各县区的企业、家政员数量
export function getAreaCount() {
    return request({
        url: '/user/area/enterprise-count',
        method: 'get',
    })
}
//服务类型
export function getFwLx() {
    return request({
        url: '/service-type-dictionary/list-tree',
        method: 'get',
    })
}


