<template>
  <div style="width: 100%; background-color: #0059b3">
    <div
      style="
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        justify-content: space-between;
      "
    >
      <div style="display: flex; align-items: center">
        <img
          style="width: 70px; height: 70px; margin-right: 10px"
          src="../static/logow.png"
        />
        <div>
          <h1
            style="
              margin: 0 0 5px 0;
              letter-spacing: 2px;
              color: #fff;
              font-weight: 500;
            "
          >
            唐山家政综合服务管理平台
          </h1>
          <p style="color: #fff; margin: 0">
            Tangshan Domestic Integrated Service Management Platform
          </p>
        </div>
      </div>
      <div
        style="display: flex; align-items: center; color: #fff; margin: 20px 0"
      >
        <div style="text-align: center; margin-right: 20px">
          <img
            style="width: 120px; height: 120px"
            src="../static/xiaochengxu.jpg"
          />
          <p style="margin: 5px 0">扫一扫打开小程序</p>
        </div>
      </div>
    </div>
    <div style="height: 1px; width: 100%; background-color: #999999"></div>
    <p style="text-align: center; margin: 0; padding: 15px; color: #fff">
      <span style="cursor: pointer" @click="ba">冀ICP备2024054531号-1</span>
      &nbsp;&nbsp; Copynight (c) 唐山家政综合服务管理平台
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    ba() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>

<style>
</style>