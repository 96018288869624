import axios from 'axios'
import { Message } from 'element-ui'
import { getToken } from './auth'
// import qs from 'qs'

// create an axios instance
const service = axios.create({
  baseURL: 'http://82.156.183.64:61618',
  // baseURL: '/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  withCredentials: false // 是否跨域
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 2 * 1000
      })

      // if (res.code === 201) {
      //   // to re-login
      //   MessageBox.confirm('登录状态失效,请重新登录', '重新登录', {
      //     confirmButtonText: '重新登录',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject('error')
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
